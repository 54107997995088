import ReactPlayer from 'react-player'
import "./App.css"


export const Stream = () => {
    return <div style={{display:"flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh"}}>
            <ReactPlayer url="https://arnoldg.freeddns.org/hls/test_stream.m3u8" height={"70vh"} width={"70vw"} playing controls />
        </div>
}

export default Stream;